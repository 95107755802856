<template>
    <div>
         <!-- 面包屑区域 -->
         <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

          <!-- 卡片视图区域 -->
          <el-card shadow="hover">
            <Title :title="title[0]"></Title>
      
             <!-- 筛选按钮区域 -->
             <div class="screen">
                  <el-button :type="Checked==item.type ? 'primary' : 'info'" v-for="item in priceDetailsData.typearr" :key="item.type" @click="changeScreen(item.type,item)">{{item.name}}</el-button>
             </div>

             <!-- 列表区域  border加边框 stripe隔行变色 -->
            <el-table v-if="priceDetailsData.type==1" :data="priceDetailsData.priceinfo" border stripe :header-cell-style="{textAlign: 'center',color:'#ffffff'}" :cell-style="{textAlign: 'center'}" header-cell-class-name="table_header"> 
                 <el-table-column type="index" label="序号" width="50px"></el-table-column>
                 <el-table-column label="基础服务" prop="name"></el-table-column>
                 <el-table-column label="垫付区间" prop="section"></el-table-column>
                 <el-table-column label="价格" prop="price"></el-table-column>
            </el-table>

            <el-table v-else :data="priceDetailsData.priceinfo" border stripe :header-cell-style="{textAlign: 'center',color:'#ffffff'}" :cell-style="{textAlign: 'center'}" header-cell-class-name="table_header">
                 <el-table-column type="index" label="序号" width="50px"></el-table-column>
                 <el-table-column label="基础服务" prop="name"></el-table-column>
                 <el-table-column label="价格" prop="price"></el-table-column>
            </el-table>

          </el-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name:'priceDetails',
    data(){
        return{
                title: ["价格明细"],
                //面包屑数据
                breadcrumbData: [
                  {
                    id: 1,
                    title: "好物电商",
                    path: "",
                    isClick: false
                  },
                  {
                    id: 2,
                    title: "价格明细",
                    path: "pricedetails",
                    isClick: true
                  }
                ],
                //选中的按钮
                Checked:1
        }
    },
    computed:{
           ...mapState({
               priceDetailsData:state => state.priceDetails.priceDetailsData
           })
    },
    methods:{
           //获取价格明细数据
           getPriceDetailsData(type){
               this.$store.dispatch('getPriceDetailsData',type)
           },
           //切换筛选
           changeScreen(type,cliskitem){
                this.Checked = type
                this.getPriceDetailsData(type)
           }
    },
    created(){
           this.getPriceDetailsData()
    }

}
</script>

<style lang="less" scoped>
// @assets:"~@/assets";
// @import "@{assets}/css/theme.less";
.screen{
    margin-top:20px;
}
.el-button{
    margin-right:50px;
}
.el-table{
    width:80%;
    margin-left:5%;
    margin-top:20px;
}
/deep/ .table_header{
    background:rgba(@themeColor,1) !important;
}

.el-card {
  margin: 20px 20px 50px 20px;
}
</style>